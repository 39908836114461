import {PLATFORM} from 'aurelia-pal';
import {EventAggregator} from 'aurelia-event-aggregator';
import {Router, RouterEvent} from 'aurelia-router';
import {inject} from 'aurelia-framework';
import Backend from "./backend";
import {IdentityProvider} from "devtag-aurelia-auth-plugin";
import {LoggingErrorRenderer} from "./util/logging-error-renderer";
import {UserInfoProvider} from "./util/user-info-provider";
import {CookieConsentService} from "./components/cookie-consent-service";

@inject(EventAggregator, Router, Backend, IdentityProvider, LoggingErrorRenderer, UserInfoProvider, CookieConsentService)
export class App {
  backend;
  identityProvider;
  loggingErrorRenderer;
  userInfoProvider;
  cookieConsentService;

  constructor(ea, router, backend, identityProvider, loggingErrorRenderer, userInfoProvider, cookieConsentService) {
    this.backend = backend;
    this.identityProvider = identityProvider;
    this.loggingErrorRenderer = loggingErrorRenderer;
    this.userInfoProvider = userInfoProvider;
    this.cookieConsentService = cookieConsentService;

    // This works for both page loads and on page routing failures
    ea.subscribe(RouterEvent.Error, (event) => {
      let routeName = event.instruction?.config?.name;

      // Assert that we know which route failed. If we don't know our protection for infinite recursion won't work.
      if (!routeName) {
        console.log(event);
        throw new Error('Router error handler was called with an event that didn\'t have the expected routing info [event.instruction.config.name].');
      }

      // Get error
      let error = event.result.output;

      // Assert that we are not in a recursive failure
      if (routeName === 'error-screen') {
        // If we have the error, throw it
        if (error) {
          throw error;
        } else {
          throw new Error('Error screen failed, but router error handler was not given the error details.');
        }
      }
      let newVar = {
        navigationResult: event.result,
        navigationInstructionData: {
          routeName: event.instruction.config.name,
          params: event.instruction.params,
          queryParams: event.instruction.queryParams
        }
      };
      if (error instanceof Error) {
        newVar.error = {
          name: error.name,
          message: error.message,
          stack: error.stack
        }
      }

      // Remove old data so we don't clog up the client
      sessionStorage.removeItem('error-data_')
      for (let key in sessionStorage) {
        if (key.startsWith('error-data_')) {
          sessionStorage.removeItem(key)
        }
      }

      // Store new data
      // Make sure to use a unique key so that router is forced to update the page in retry failure cases.
      let sessionStorageKey = 'error-data_' + new Date().toISOString(); //Use different key every time
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(newVar));

      // Send to backend
      this.loggingErrorRenderer.render(
        error.name,
        error.message,
        error.stack,
        error);

      // Navigate to error screen
      router.navigateToRoute('error-screen', {sessionStorageKey: sessionStorageKey});
    })
  }

  async configureRouter(config, router) {
    // Load user info BEFORE we go on. We need this to know what to render.
    await this.userInfoProvider.load();
    let subscriptionId = this.userInfoProvider.getInfo().subscriptionId;
    let subscriptionProductId = this.userInfoProvider.getInfo().subscriptionProductId;
    let existingCrateProductName = this.userInfoProvider.getInfo().existingCrateProductName;

    // Title
    config.title = 'Rågo';

    // SEO urls
    config.options.pushState = true;

    let addQueryParam_stepFirst = (instruction) => {
      instruction.config.redirect = 'checkout-flow';
      // When navigating from this menu item, always go to the first step which is subproducts (week extra or customizable).
      instruction.queryString = 'step=first';
    }

    let navigationStrategy = (instruction) => {
      let info = this.userInfoProvider.getInfo();
      instruction.config.redirect = `change-crate-product/${(info.subscriptionId)}/${(info.subscriptionProductId)}/${(info.existingCrateProductName)}`
    };

    config.map([
      { route: ['', 'hjem', 'home'],           name: 'home',                        moduleId: PLATFORM.moduleName('app/home'),                               nav: false,  title: 'Hjem',       public: true },
      { route: 'go',                          name: 'go',                          moduleId: PLATFORM.moduleName('app/go'),                                 nav: false,  title: 'Hjem',       public: true },
      { route: ['hjem-kort', 'home-short'],   name: 'home-short',                  moduleId: PLATFORM.moduleName('app/home-short'),                         nav: false,  title: 'Hjem - kort', public: true },
      { route: 'style-guide',                 name: 'style-guide',                 moduleId: PLATFORM.moduleName('./style-guide'),                          nav: false,  title: 'Style guide', public: true },

      // Matkasse routes
      // NOTE: nav bar visibility is altered by filters in nav-bar.html
      // This screen has two routes. One to show in the menu, and one that we can change the title of (for seo reasons) without changing the navbar title.
      { route: ['enkeltprodukter', 'single-product-catalog'], name: 'single-product-catalog-default', moduleId: PLATFORM.moduleName('app/single-product-catalog-screen'), nav: true, title: 'Enkeltprodukter', nonAuthOnly: true, href: 'enkeltprodukter' },
      { route: ['enkeltprodukter/:productCategorySeoKey?'],   name: 'single-product-catalog', moduleId: PLATFORM.moduleName('app/single-product-catalog-screen'), nav: false, title: 'Enkeltprodukter', nonAuthOnly: true, href: 'enkeltprodukter' },
      { route: 'change-crate-product-single',                 name: 'change-crate-product-single', navigationStrategy: navigationStrategy,                                  nav: true, title: 'Endre kassetype', authOnly: true },
      { route: 'change-crate-product' +
          '/:subscriptionId' +
          '/:subscriptionProductId' +
          '/:existingCrateProductName',
                                                                                   moduleId: PLATFORM.moduleName('app/change-crate-product-screen'), name: 'change-crate-product',                  nav: false, title: 'Matkasser', authOnly: true },

      { route: 'checkout-flow',               name: 'checkout-flow',               moduleId: PLATFORM.moduleName('app/checkout-flow/checkout-flow-screen'), nav: false, title: 'Handlekurv',        public: true },

      { route: 'instant-checkout', name: 'instant-checkout-route', moduleId: PLATFORM.moduleName('app/instant-checkout-route'),      nav: false, authOnly: true },

      // Additional products routes
      // NOTE: nav bar visibility is altered by filters in nav-bar.html
      { route: 'week-extra/:subscriptionId',  name: 'week-extra',                  moduleId: PLATFORM.moduleName('app/week-extra-screen'),                  nav: true,  title: 'Tilleggsprodukter', authOnly: true, href: `week-extra/auto` }, // change week extra
      { route: 'crates-and-bundles/:subscriptionId',  name: 'crates-and-bundles',  moduleId: PLATFORM.moduleName('app/crates-and-bundles-screen'),          nav: true,  title: 'Kasser/Pakker', authOnly: true, href: `crates-and-bundles/auto` }, // change extra crates and bundles
      { route: 'checkout-flow-customizable',  name: 'checkout-flow-customizable',  navigationStrategy: addQueryParam_stepFirst,                             nav: false,  title: 'Enkeltprodukter',   public: true },                                         // checkout flow -> customizable
      {                                       name: 'change-customizable-content', moduleId: PLATFORM.moduleName('app/change-customizable-content-screen'), nav: true,  title: 'Enkeltprodukter',   authOnly: true,                                         // change customizable
        route: 'change-customizable-content/:subscriptionId/:subscriptionProductId',
        href: `change-customizable-content/${subscriptionId}/${subscriptionProductId}`,
      },

      // NOTE: nav bar visibility is altered by filters in nav-bar.html
      { route: ['matkasser', 'crate-catalog'],                      name: 'crate-catalog',               moduleId: PLATFORM.moduleName('app/crate-catalog-screen'),               nav: true, title: 'Matkasser', public: true },
      { route: ['matkasser/:productIdOrSeoKey', 'product/:productIdOrSeoKey'], name: 'crate-product',    moduleId: PLATFORM.moduleName('app/crate-product-screen'),               nav: false, title: 'Matkasse', public: true },
      { route: ['produkter/:productIdOrSeoKey'],                    name: 'product',                     moduleId: PLATFORM.moduleName('app/product-screen'),                     nav: false, title: 'Produkt', public: true },
      { route: ['oppskrifter', 'recipe-index' ],                    name: 'recipe-index',             moduleId: PLATFORM.moduleName('app/recipe-index-screen'),             nav: true,  title: 'Oppskrifter', public: true },
      { route: ['oppskrifter/:recipeIdOrSeoKey', 'recipe/:recipeIdOrSeoKey'], name: 'recipe',            moduleId: PLATFORM.moduleName('app/recipe-screen'),                      nav: false, title: 'Oppskrift', public: true },
      { route: ['produsenter', 'supplier-index'],                   name: 'supplier-index',              moduleId: PLATFORM.moduleName('app/supplier-index-screen'),              nav: true,  title: 'Produsenter', public: true },
      { route: ['produsenter/:supplierIdOrSeoKey', 'supplier/:supplierIdOrSeoKey'], name: 'supplier',    moduleId: PLATFORM.moduleName('app/supplier-screen'),                    nav: false, title: 'Produsent', public: true },
      { route: ['nyheter', 'news-item-index'],                      name: 'news-item-index',             moduleId: PLATFORM.moduleName('app/news-item-index-screen'),             nav: true,  title: 'Nyheter', public: true },
      { route: ['nyheter/:newsItemIdOrSeoKey', 'news-item/:newsItemIdOrSeoKey'], name: 'news-item',      moduleId: PLATFORM.moduleName('app/news-item-screen'),                   nav: false, title: 'Nyhet', public: true },
      { route: ['om-oss', 'about-us'],                              name: 'about-us',                    moduleId: PLATFORM.moduleName('app/about-us'),                           nav: true,  title: 'Om Rågo', public: true },

      { route: 'cms-page/:cmsFragmentKey',    name: 'cms-page',                    moduleId: PLATFORM.moduleName('app/cms-page-screen'),                    nav: false, title: 'Rågo', public: true },

      { route: 'error-screen',    name: 'error-screen',     moduleId: PLATFORM.moduleName('./error-screen'), nav: false, title: 'Error', public: true},
      { route: 'receipt',         name: 'receipt', moduleId: PLATFORM.moduleName('app/checkout-flow/receipt-screen'), nav: false, title: 'Kvittering', authOnly: true },

      // The url for my-page is also referenced in RaagoEnvironmentTemplateScript.java, so make to change it in both places.
      { route: 'my-page',                                    name: 'my-page', moduleId: PLATFORM.moduleName('app/my-page-screen'), nav: true, title: 'Min kasse', authOnly: true }, //
      { route: 'my-order-details',                           name: 'my-order-details', moduleId: PLATFORM.moduleName('app/my-order-details-screen'), nav: false, title: 'Ordredetaljer', authOnly: true }, //
      { route: ['min-kasse', 'my-page-public'],              name: 'my-page-public', moduleId: PLATFORM.moduleName('app/my-page-public'), nav: true, title: 'Logg inn', nonAuthOnly: true }, //
      { route: 'cancel-subscription/:subscriptionId',        name: 'cancel-subscription', moduleId: PLATFORM.moduleName('app/cancel-subscription-screen'), nav: false, title: 'Avslutt abonnement', authOnly: true },
      { route: 'change-address/:subscriptionId',             name: 'change-address', moduleId: PLATFORM.moduleName('app/change-address-screen'), nav: false, title: 'Endre adresse', authOnly: true },
      { route: 'change-comment-for-packing/:subscriptionId', name: 'change-comment-for-packing', moduleId: PLATFORM.moduleName('app/change-comment-for-packing-screen'), nav: false, title: 'Endre kommentar til pakking', authOnly: true },
      { route: 'change-interval/:subscriptionId',            name: 'change-interval', moduleId: PLATFORM.moduleName('app/change-interval-screen'), nav: false, title: 'Bytt intervall', authOnly: true },
      { route: 'change-payment-means/:subscriptionId',       name: 'change-payment-means', moduleId: PLATFORM.moduleName('app/change-payment-means-screen'), nav: false, title: 'Endre betalingsmiddel', authOnly: true },
      { route: ['kjop-gavekort', 'gift-card-purchase-without-payment-means'],   name: 'gift-card-purchase-without-payment-means', moduleId: PLATFORM.moduleName('app/gift-card-purchase-without-payment-means-screen'), nav: false, title: 'Kjøp gavekort', nonAuthOnly: true },
      { route: 'gift-card-purchase',                         name: 'gift-card-purchase', moduleId: PLATFORM.moduleName('app/gift-card-purchase-screen'), nav: false, title: 'Kjøp gavekort', authOnly: true },
      { route: 'gift-card-claim',                            name: 'gift-card-claim', moduleId: PLATFORM.moduleName('app/gift-card-claim-screen'), nav: false, title: 'Innløs gavekort', authOnly: true },
      { route: 'gift-card-printable/:giftCardEncoded',       name: 'gift-card-printable', moduleId: PLATFORM.moduleName('app/gift-card-printable-screen'), nav: false, title: 'Gavekort', public: true },

      { route: 'logout',        name: 'logout',        moduleId: PLATFORM.moduleName('./logout'),        nav: true,  title: 'Logg ut', public: true },
      { route: 'no-permission', name: 'no-permission', moduleId: PLATFORM.moduleName('./no-permission'), nav: false, title: 'route.no-permission', public: true },
    ]);


    const handleUnknownRoutes = (instruction) => {
      // Redirect to 404 URL if not already there.
      // Reason: we want response code 404 from the server, so we need to cause a page load, and we need to load a page we know will have status code 404.
      const isOn404Url = window.location.pathname.startsWith('/404/');
      if (!isOn404Url) {
        window.location.pathname = '/404' + window.location.pathname + window.location.search
        return;
      }

      // Pretend we are still on the original url, not the path that contains '/404'
      let path = instruction.params.path ?? '';
      if (path.startsWith('/404/')) {
        window.history.pushState({}, '', decodeURIComponent(path.replace('/404/', '')));
      }

      // Show the not-found route
      return {route: 'not-found', moduleId: PLATFORM.moduleName('not-found'), public: true};
    }

    config.mapUnknownRoutes(handleUnknownRoutes);
    config.fallbackRoute('/');

    this.router = router;

    config.addPreRenderStep({
      run(navigationInstruction, next) {
        // Remove old link element
        let existingLinkElement = document.head.querySelector('link[rel="canonical"]');
        if (existingLinkElement) {
          existingLinkElement.remove();
        }

        // Add new link element
        let canonicalRouteName = navigationInstruction.config.canonical ?? navigationInstruction.config.name;
        if (canonicalRouteName) {
          let canonicalHref = router.generate(canonicalRouteName, navigationInstruction.params, {absolute: true});
          let linkElement = document.createElement('link');
          linkElement.setAttribute('rel', 'canonical');
          linkElement.href = canonicalHref;
          document.head.appendChild(linkElement);
        }

        // Go on to the next step
        return next();
      }
    });
    // Scroll to top when navigating to new page
    config.addPostRenderStep({
      run(navigationInstruction, next) {
        if (navigationInstruction.router.isNavigatingNew) {
          window.scroll(0, 0);
        }
        return next();
      }
    });
  }

  showConsentModal() {
    this.cookieConsentService.showConsentsModalForEdit();
  }
}
