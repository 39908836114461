import {inject, observable} from 'aurelia-framework';
import {Redirect, RedirectToRoute, Router} from 'aurelia-router';
import Backend from "../backend";
import {SpecialPriceCalculator} from "./checkout-flow/specialPriceCalculator";
import {RMatomo} from "../util/r-matomo";
import {UserInfoProvider} from "../util/user-info-provider";

@inject(Backend, Router, SpecialPriceCalculator, UserInfoProvider)
export class WeekExtraScreen {
  backend;
  router;
  specialPriceCalculator;
  userInfoProvider;

  chosenProducts = [];
  initValues = {};

  @observable({changeHandler: 'valueOfGoodsChanged'})
  valueOfGoods;

  constructor(backend, router, specialPriceCalculator, userInfoProvider) {
    this.backend = backend;
    this.router = router;
    this.specialPriceCalculator = specialPriceCalculator;
    this.userInfoProvider = userInfoProvider;
  }

  attached() {
    return this.backend.CmsFragmentPublicQueryHandler_handle({items: [
          {cmsFragmentKey: "week_extra__section1"}
        ]})
      .then(result => {
        this.cmsFragments = result;
      });
  }

  canActivate(params) {
    let userInfo = this.userInfoProvider.getInfo();

    // No subscription? -> Redirect to my-page
    if (!userInfo.hasSubscription) {
      return new Redirect('my-page');
    }

    // Any customizable crate? -> Redirect to change-customizable-content
    if (userInfo.isCustomizable) {
      return new RedirectToRoute('change-customizable-content', {subscriptionId: userInfo.subscriptionId, subscriptionProductId: userInfo.subscriptionProductId});
    }

    return true;
  }

  activate(params) {
    return this.backend.MyPageQueryHandler_handle({})
      .then(result => {
        // Find subscription
        let subscription;
        if (params.subscriptionId === 'auto') {
          subscription = result.subscriptions[0];
          this.subscriptionId = subscription.subscriptionId;
        } else {
          this.subscriptionId = params.subscriptionId;
          subscription = result.subscriptions
            .filter(subscription => subscription.subscriptionId === params.subscriptionId)
            [0];
        }

        // Put each line quantity in the init values
        subscription
          .lines
          .forEach(subscriptionLine => {
            this.initValues[subscriptionLine.productId] = {
                quantity: subscriptionLine.quantity,
                everyTime: !subscriptionLine.oneTime
              };
          });

        this.subscription = subscription;
      });
  }

  valueOfGoodsChanged() {
    // Total price
    let firstCrateProduct = this.subscription.lines.filter(line => line.isCrate)[0];
    if (!firstCrateProduct) {
      return;
    }
    let productId = firstCrateProduct.productId;
    this.calculatedPrice = this.specialPriceCalculator.calculateSumIncVat(productId, this.valueOfGoods);
  }

  purchaseExtras() {
    let items = this.chosenProducts.map(product => ({productId: product.productId, quantity: product.quantity, oneTime: !product.everyTime}));
    return this.backend.ChangeWeekExtraCommandHandler_handle({subscriptionId: this.subscriptionId, lines: items})
      .then(() => RMatomo.trackGoalConversion(RMatomo.GOAL_ID__WEEK_EXTRA))
      .then(() => this.router.navigateToRoute('my-page'))
  }
}
