import {inject, TaskQueue} from 'aurelia-framework';
import Backend from "../backend";
import {redirectToActiveSeoKey} from "../util/redirect-to-active-seo-key";
import {MetaTags} from "../util/meta-tags";
import {Router} from "aurelia-router";
import {UserInfoProvider} from "../util/user-info-provider";

@inject(Backend, Router, UserInfoProvider, TaskQueue)
export class SupplierScreen {
  backend;
  router;
  userInfoProvider;
  crateChoiceModalComponentRef;
  taskQueue;

  constructor(backend, router, userInfoProvider, taskQueue) {
    this.backend = backend;
    this.router = router;
    this.userInfoProvider = userInfoProvider;
    this.taskQueue = taskQueue;
  }

  canActivate(params, routeConfig) {
    return this.backend.SupplierIndexQueryHandler_handle({supplierIdOrSeoKey: params.supplierIdOrSeoKey})
        .then(result => {
          this.supplier = result.suppliers[0];

          // SEO: Title
          routeConfig.navModel.setTitle(this.supplier.name + ' | ' + routeConfig.title);

          // SEO: Meta tags
          MetaTags.setMetaTags_rememberToCallCleanOnUnbind(this.supplier.descriptionMetaTag);

          // SEO: handle seokey
          return redirectToActiveSeoKey(params, routeConfig, 'supplierIdOrSeoKey', this.supplier.seoKey);
        });
  }

  buy(product) {
    if (this.userInfoProvider.getInfo().hasSubscription) {
      this.router.navigateToRoute('instant-checkout-route', {checkoutProductId: product.productId});
    } else {
      this.crateChoiceModalComponentRef.show([{productId: product.productId, quantity: 1}]);
    }
  }

  unbind() {
    MetaTags.cleanMetaTags();
  }
}
