import {inject} from 'aurelia-framework';
import Backend from "../backend";
import {activationStrategy, Redirect, RedirectToRoute, Router} from 'aurelia-router';
import {redirectToActiveSeoKey} from "../util/redirect-to-active-seo-key";
import {MetaTags} from "../util/meta-tags";

@inject(Backend, Router)
export class ProductScreen {
  backend;
  router;

  visibleBundleConfiguration;

  constructor(backend, router) {
    this.backend = backend;
    this.router = router;
  }

  canActivate(params, routeConfig) {
    return this.backend.ProductDetailsQueryHandler_handle({productIdOrSeoKey: params.productIdOrSeoKey, maxAdditionalWeekCount: 2})
        .then(result => {
          if (result === null) {
             throw new Error('Product not found: ' + params.productIdOrSeoKey);
          }

          this.product = result;

          // Enforce non-crates only because the url is "produkt" and there is a separate url for "matkasse"
          if (this.product.isCrate) {
            throw new Error('This screen can show only non-crate products. productIdOrSeoKey: ' + params.productIdOrSeoKey);
          }

          // SEO: Title
          routeConfig.navModel.setTitle(this.product.name + ' | ' + routeConfig.title);

          // SEO: Meta tags
          MetaTags.setMetaTags_rememberToCallCleanOnUnbind(this.product.descriptionMetaTag);

          // SEO: structured data
          let structuredData = {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": this.product.nameAndUnit,
            "image": this.product.imageUrl,
            "description": this.product.description,
            "brand": {
              "@type": "Brand",
              "name": "Raago"
            },
            "offers": {
              "@type": "Offer",
              "price": this.product.priceIncVat,
              "priceCurrency": "NOK",
              "availability": "https://schema.org/InStock"
            }
          };
          this.productStructuredDataJson = JSON.stringify(structuredData);

          // SEO: handle seokey
          return redirectToActiveSeoKey(params, routeConfig, 'productIdOrSeoKey', this.product.seoKey);
        });
  }

  activate() {
    return Promise.all([

    ]);
  }

  unbind() {
    MetaTags.cleanMetaTags();
  }

  // When we retry and it fails again we need to reload this screen with new data, not just keep the view model we have.
  determineActivationStrategy() {
    return activationStrategy.replace;
  }
}
